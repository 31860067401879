<script>
    const coefficients = [0.014, 0.003, 0.236]

    const firstJanuary = new Date('2023-01-01').valueOf() / 1000
    const now = new Date().valueOf() / 1000
    const diff = now - firstJanuary

    let totals = [0, 0, 0]

    // valores iniciales
    for (let i = 0; i < totals.length; i++) {
        totals[i] += coefficients[i] * diff
    }

    setInterval(() => {
        for (let i = 0; i < totals.length; i++) {
            totals[i] += coefficients[i]
        }
        console.log(totals)
    }, 1000)
</script>

<main>
    <!-- ocupa el lugar de la imagen de fondo -->
    <div class="globo" />
    <article>
        Trazemos progresso à vida por meio de ações concretas que evitam a emissão de Gases de Efeito Estufa (CO<sub
            ><small>2</small></sub
        >) na produção de proteína animal.
    </article>
    <article>
        Em 2023, já alcançamos uma <b
            >redução de emissões de CO<sub><small>2</small></sub> na produção suína no Brasil</b
        > de:
    </article>
    <article class="indicator">
        <img src="assets/imgs/temperature.png" width="160" alt="icono de globo terraqueo con un termometro" />
        <div>
            {totals[0].toLocaleString('pt', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} tn CO<sub
                ><small>2</small></sub
            >e
        </div>
    </article>
    <article class="article-small">Essa redução é equivalente a:</article>
    <article class="indicator-with-text-bar-container last">
        <div class="indicator-with-text-bar">
            <div class="indicator-with-text">
                <div class="indicator-small">
                    <img
                        class="vehicle"
                        src="assets/imgs/vehicle.png"
                        height="60"
                        alt="icono de un automovil con el icono de reciclado en el centro"
                    />
                    <div class="number-small number-vehicle">
                        <div>
                            {totals[1].toLocaleString('pt', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}
                        </div>
                    </div>
                </div>
                <div class="indicator-text">
                    <div class="indicator-title">Veículos</div>
                    <div class="indicator-caption">que não foram dirigidos ao ano</div>
                </div>
            </div>
        </div>
        <div class="indicator-with-text-bar">
            <div class="indicator-with-text">
                <div class="indicator-small">
                    <img
                        src="assets/imgs/hand.png"
                        height="60"
                        alt="icono una planta creciendo de la palma de una mano"
                    />
                    <div class="number-small number-plant">
                        <div>
                            {totals[2].toLocaleString('pt', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}
                        </div>
                    </div>
                </div>
                <div class="indicator-text">
                    <div class="indicator-title">Árvores</div>
                    <div class="indicator-caption">cultivadas por 10 anos</div>
                </div>
            </div>
        </div>
    </article>
</main>

<style>
    .last {
        margin-bottom: 30px;
    }
    .number-small {
        font-size: 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .number-plant {
        min-width: 180px;
    }
    .number-vehicle {
        min-width: 180px;
    }
    .bar {
        padding-top: 30px;
    }
    .vehicle {
        padding-top: 0px;
    }
    sub {
        vertical-align: baseline;
        position: relative;
        top: -0.4em;
    }
    sub {
        top: 0.4em;
    }

    .indicator-text {
        display: flex;
        flex-direction: column;
    }
    .indicator-title {
        font-size: 40px;
        font-style: italic;
        font-weight: bold;
    }

    .indicator-caption {
        font-size: 28px;
        font-style: italic;
    }

    .indicator-with-text {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
    }
    .indicator {
        display: flex;
        align-items: center;
        font-size: 55px;
        justify-content: space-between;
        padding-right: 10%;
    }

    .indicator-small {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .indicator-with-text-bar-container {
        display: flex;
        padding-left: 55px;
        padding-right: 55px;
        align-items: end;
        gap: 50px;
    }

    .indicator-with-text-bar {
        display: flex;
        gap: 8px;
        min-width: 360px;
        min-height: 260px;
    }

    .globo {
        height: 600px;
    }
    @media (orientation: landscape) {
        .globo {
            height: 630px;
        }
    }
    small {
        font-size: 18px;
    }
    .indicator-small small {
        font-size: 14px;
    }

    main article {
        padding-left: 70px;
        padding-right: 70px;
        font-size: 32px;
        letter-spacing: 0.116rem;
        line-height: 2.5rem;
    }

    main article.article-small {
        font-size: 28px;
    }

    main {
        background-image: url('imgs/background.jpg');
        background-size: cover;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
</style>
